<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Reports</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Department"
              invalid-feedback="Department is required."
              ref="rep_dep"
            >
              <!-- multiple
            :closeOnSelect="false" -->
              <v-select
                v-model="repObj.depIDs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="departments"
                :reduce="(val) => val.id"
                label="name"
                :clearable="false"
                placeholder="Select department"
              />
              <!-- @input="setDept()" -->
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback=" date is required."
              ref="rep_date"
            >
              <flat-pickr
                :config="config2"
                v-model="repDate"
                class="form-control"
                placeholder="Select Date"
                @on-change="setRepDate"
              />
            </b-form-group>
          </b-col>

          <!--  <b-col md="12" class="mt-1">
             <b-form-group
              label="Status"
              invalid-feedback="status is required."
              ref="rep_status"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                ref="rep_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="repObj.status"
                :options="statusOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
                @input="setStatus()"
              />
            </b-form-group>
          </b-col> -->
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="getReport()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Show</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility4"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Mark Holiday</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility4 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Department"
              invalid-feedback="Department is required."
              ref="hol_dep"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="holiObj.departmentIDs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="departments"
                :reduce="(val) => val.id"
                label="name"
                :clearable="false"
                @input="setHoliDept()"
                ref="hol_dep"
                placeholder="Select department"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date From"
              invalid-feedback=" date is required."
              ref="hol_dateF"
            >
              <flat-pickr
                ref="hol_dateF"
                :config="config"
                v-model="holiObj.dtFrom"
                class="form-control"
                placeholder="Select Date"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date Till"
              invalid-feedback=" date is required."
              ref="hol_dateT"
            >
              <flat-pickr
                ref="hol_dateT"
                :config="config"
                v-model="holiObj.dtTo"
                class="form-control"
                placeholder="Select Date"
              />
            </b-form-group>
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="markHoliday()"
              :disabled="marking"
              block
            >
              <b-spinner v-if="marking" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Send Messsage</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1" style="align-items: center">
          <b-col md="12">
            <b-form-group
              label="Department"
              invalid-feedback="Department is required."
              ref="msg_dep"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="msgIDs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="departments"
                :reduce="(val) => val.id"
                label="name"
                :clearable="false"
                @input="setMsgDept()"
                ref="msg_dep"
                placeholder="Select department"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback=" date is required."
              ref="msg_date"
            >
              <flat-pickr
                ref="msg_date"
                :config="config"
                v-model="msgObj.dt"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Select Status"
              invalid-feedback="status is required."
              ref="msg_status"
            >
              <v-select
                ref="msg_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="msgObj.status"
                :options="statusOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="saveMessage()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Send Message</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1">
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Biometric</h4>
            <b-form-checkbox
              v-model="attSettings.attendanceMode"
              value="biometric"
              unchecked-value=""
              switch
            />
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Notify Teacher</h4>
            <b-form-checkbox v-model="notiTeacher.valueBool" switch />
          </b-col>

          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Notify Manager</h4>
            <b-form-checkbox v-model="notiManager.valueBool" switch />
          </b-col>
          <b-col v-if="notiManager.valueBool" md="12">
            <b-form-group
              label="Manager's Contact"
              invalid-feedback="Please enter correct phone number."
            >
              <vue-tel-input
                :inputOptions="vueTelOptions"
                :dropdownOptions="dropOptions"
                @input="CheckContact"
                v-model="notiManager.value"
              ></vue-tel-input>
              <small class="mr-1 text-danger" v-if="phoneerror"
                >Phone number is required.</small
              >
            </b-form-group>
          </b-col>

          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Double Absent</h4>
            <b-form-checkbox v-model="doubleObj.valueBool" switch />
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="saveSettings()"
              :disabled="savingSettings || (!rights.add && !rights.edit)"
              block
            >
              <b-spinner v-if="savingSettings" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-modal
      id="modal-att"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      title="Edit Attendance"
    >
      <b-row class="">
        <b-col>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'P' ? 'success' : 'outline-success'"
            @click="currentObj.status = 'P'"
          >
            P
          </b-button>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'A' ? 'danger' : 'outline-danger'"
            @click="currentObj.status = 'A'"
          >
            A
          </b-button>
          <b-button
            v-if="doubleObj && doubleObj.valueBool == true"
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'AA' ? 'danger' : 'outline-danger'"
            @click="currentObj.status = 'AA'"
          >
            AA
          </b-button>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'L' ? 'info' : 'outline-info'"
            @click="currentObj.status = 'L'"
          >
            L
          </b-button>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'SL' ? 'info' : 'outline-info'"
            @click="currentObj.status = 'SL'"
          >
            SL
          </b-button>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="
              currentObj.status.toLowerCase() == 'late'
                ? 'warning'
                : 'outline-warning'
            "
            @click="currentObj.status = 'Late'"
          >
            Late
          </b-button>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'H' ? 'info' : 'outline-info'"
            @click="currentObj.status = 'H'"
          >
            H
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="d-flex justify-content-end">
          <b-button
            @click="saveAtt()"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="success"
            :disabled="savingAtt"
          >
            <b-spinner v-if="savingAtt" small type="grow" />
            <span v-else class="align-middle">Save</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <template v-if="showDetails">
      <b-row class="mb-1">
        <b-col>
          <b-media vertical-align="center">
            <template #aside>
              <b-button
                variant="outline-secondary"
                class="btn-icon mr-1 rounded-circle"
                @click="showDetails = false"
                v-b-tooltip.hover.top
                title="Back"
              >
                <feather-icon class="" size="18" icon="ArrowLeftIcon" />
                <!-- Back -->
              </b-button>
              <b-avatar
                size="44"
                :src="detailObj.picture"
                variant="light-primary"
              />
            </template>

            <b-link
              class="font-weight-bold d-block text-nowrap"
              style="margin-bottom: 4px"
            >
              <b-badge variant="light-primary">
                {{ detailObj.name }}
              </b-badge>
            </b-link>
            <b-link
              class="font-weight-bold d-block text-nowrap"
              style="margin-bottom: 4px"
            >
              <b-badge variant="light-primary">
                F/N: {{ detailObj.father_name }}
              </b-badge>
            </b-link>
            <small class="text-muted">
              <b-badge variant="light-primary">
                {{ detailObj.designation }}
              </b-badge>
            </small>
          </b-media>
        </b-col>
      </b-row>

      <b-card>
        <b-row>
          <b-col class="d-flex align-items-start flex-wrap">
            <b-button
              class="btn-icon mr-50 mb-1"
              v-for="mon in months"
              :key="mon.value"
              :variant="attMonth == mon.value ? 'primary' : 'outline-primary'"
              @click="change(mon.value)"
            >
              {{ mon.text.slice(0, 3) }}
            </b-button>
            <div style="width: 120px">
              <b-form-group label="">
                <v-select
                  v-model="attYear"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="years"
                  :clearable="false"
                  placeholder="Select year"
                  @input="loadAtt()"
                />
              </b-form-group>
            </div>
          </b-col>
        </b-row>

        <b-row align-h="center" v-if="dataLoading" class="mt-5 mb-5">
          <b-spinner
            style="width: 3rem; height: 3rem"
            variant="primary"
            label="Spinning"
            type="grow"
          ></b-spinner>
        </b-row>
        <b-row class="hover-card mt-1" v-else>
          <b-col md="3">
            <b-card bg-variant="success" text-variant="white" class="">
              <h4 class="text-white font-weight-bolder">Present</h4>
              <p class="font-weight-bolder mb-0 text-right summary-count">
                {{ countObj.present }}
              </p>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card bg-variant="danger" text-variant="white" class="">
              <h4 class="text-white font-weight-bolder">Absent</h4>
              <p class="font-weight-bolder mb-0 text-right summary-count">
                {{ countObj.absent }}
              </p>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card bg-variant="info" text-variant="white" class="">
              <h4 class="text-white font-weight-bolder">Leave</h4>
              <p class="font-weight-bolder mb-0 text-right summary-count">
                {{ countObj.leave }}
              </p>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card bg-variant="warning" text-variant="white" class="">
              <h4 class="text-white font-weight-bolder">Late</h4>
              <p class="font-weight-bolder mb-0 text-right summary-count">
                {{ countObj.late }}
              </p>
            </b-card>
          </b-col>
          <b-col md="12">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar mt-1"
            >
              <template v-slot:eventContent="arg">
                <b class="font-large-3">{{ arg.event.title }}</b>
                <p
                  v-if="arg.event.extendedProps.timeIn"
                  class="font-small-2 mb-25"
                >
                  <span class="text-dark text-uppercase">Time in: </span>
                  <b-badge
                    :variant="getVariant(arg.event.title)"
                    class="font-small-2"
                  >
                    {{
                      new Intl.DateTimeFormat("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                      }).format(new Date(arg.event.extendedProps.timeIn))
                    }}
                  </b-badge>
                </p>
                <p
                  v-if="arg.event.extendedProps.timeOut"
                  class="font-small-2 mb-0"
                >
                  <span class="text-dark text-uppercase">Time out: </span>
                  <b-badge
                    :variant="getVariant(arg.event.title)"
                    class="font-small-2"
                  >
                    {{
                      new Intl.DateTimeFormat("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                      }).format(new Date(arg.event.extendedProps.timeOut))
                    }}
                  </b-badge>
                </p>
              </template>
            </full-calendar>
          </b-col>
        </b-row>
      </b-card>

      <b-card v-if="false">
        <!-- <b-button
          variant="outline-secondary"
          class="mr-1"
          @click="showDetails = false"
        >
          <feather-icon class="" icon="ArrowLeftIcon" />
          Back
        </b-button> -->
        <b-button
          variant="outline-primary"
          @click="loadDetails('week')"
          class="mr-1"
        >
          This Week
        </b-button>
        <b-button
          variant="outline-primary"
          @click="loadDetails('month')"
          class="mr-1"
        >
          This Month
        </b-button>
        <b-button
          variant="outline-primary"
          @click="loadDetails('year')"
          class="mr-1"
        >
          This Year
        </b-button>
        <b-button
          variant="outline-primary"
          @click="loadDetails('last year')"
          class="mr-1"
        >
          Last Year
        </b-button>

        <b-table
          responsive
          :items="detailItems"
          :fields="detailFields"
          class="mt-1"
          show-empty
          :busy="dataLoading"
        >
          <template #table-busy>
            <div
              class="d-flex justify-content-center mb-3"
              style="margin-top: 50px"
            >
              <b-spinner
                style="width: 3rem; height: 3rem"
                variant="primary"
                label="Spinning"
                type="grow"
              ></b-spinner>
            </div>
          </template>
          <template #cell(status)="data">
            <b-badge
              :variant="
                data.value == 'P'
                  ? 'light-success'
                  : data.value == 'A'
                  ? 'light-danger'
                  : data.value == 'L'
                  ? 'light-info'
                  : 'light-warning'
              "
            >
              {{ data.item.status }}
            </b-badge>
          </template>
          <template #cell(date)="data">
            <b-badge variant="light-primary">
              {{
                new Date(data.value).toLocaleDateString("en-UK", {
                  year: "numeric",
                  day: "numeric",
                  month: "short",
                })
              }}
            </b-badge>
          </template>
          <template #cell(timein)="data">
            <b-badge variant="light-primary" v-if="data.item.timeIn">
              {{
                new Intl.DateTimeFormat("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                }).format(new Date(data.item.timeIn))
              }}
            </b-badge>
          </template>
          <template #cell(timeout)="data">
            <b-badge variant="light-primary" v-if="data.item.timeOut">
              {{
                new Intl.DateTimeFormat("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                }).format(new Date(data.item.timeOut))
              }}
            </b-badge>
          </template>
        </b-table>
      </b-card>
    </template>

    <template v-else>
      <b-row align-h="center" v-if="summLoading" class="mb-2">
        <b-spinner
          style="width: 3rem; height: 3rem"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </b-row>
      <b-row class="" v-else>
        <b-col xl="2" md="4">
          <b-card body-class="pl-1">
            <b-media no-body>
              <b-media-aside class="mr-50">
                <b-avatar size="44" variant="light-success">
                  <feather-icon size="24" icon="UserCheckIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="">
                <h4 class="font-weight-bolder mb-0">Present</h4>
                <b-card-text class="mb-0">
                  {{ summaryObj.present }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col>
        <b-col xl="2" md="4">
          <b-card body-class="pl-1">
            <b-media no-body>
              <b-media-aside class="mr-50">
                <b-avatar size="44" variant="light-danger">
                  <feather-icon size="24" icon="UserXIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="">
                <h4 class="font-weight-bolder mb-0">Absent</h4>
                <b-card-text class="mb-0">
                  {{ summaryObj.absent }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col>
        <b-col xl="2" md="4">
          <b-card body-class="pl-1">
            <b-media no-body>
              <b-media-aside class="mr-50">
                <b-avatar size="44" variant="light-info">
                  <feather-icon size="24" icon="PlusCircleIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="">
                <h4 class="font-weight-bolder mb-0">Leave</h4>
                <b-card-text class="mb-0">
                  {{ summaryObj.leave }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col>
        <b-col xl="2" md="4">
          <b-card body-class="pl-1">
            <b-media no-body>
              <b-media-aside class="mr-50">
                <b-avatar size="44" variant="light-danger">
                  <feather-icon size="24" icon="InfoIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="">
                <h4 class="font-weight-bolder mb-0">Short Leave</h4>
                <b-card-text class="mb-0">
                  {{ summaryObj.shortLeave }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col>
        <b-col xl="2" md="4">
          <b-card body-class="pl-1">
            <b-media no-body>
              <b-media-aside class="mr-50">
                <b-avatar size="44" variant="light-warning">
                  <feather-icon size="24" icon="ClockIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="">
                <h4 class="font-weight-bolder mb-0">Late</h4>
                <b-card-text class="mb-0">
                  {{ summaryObj.late }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col>
        <b-col xl="2" md="4">
          <b-card body-class="pl-1">
            <b-media no-body>
              <b-media-aside class="mr-50">
                <b-avatar size="44" variant="light-primary">
                  <feather-icon size="24" icon="CloudIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="">
                <h4 class="font-weight-bolder mb-0">Holidays</h4>
                <b-card-text class="mb-0">
                  {{ summaryObj.holidays }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col>
      </b-row>

      <b-card>
        <b-row style="align-items: center">
          <b-col md="2">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="date"
            >
              <flat-pickr
                :config="config"
                ref="date"
                v-model="myObj.date"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>
          <b-button
            class="ml-1"
            :disabled="gridLoading"
            variant="primary"
            @click="loadgrid()"
          >
            <!-- @click="updateLate()" -->
            Show
          </b-button>
          <b-button
            v-if="rights.add || rights.edit"
            variant="success"
            class="ml-1"
            :disabled="saving"
            @click="save()"
          >
            Save
          </b-button>
          <b-button
            v-if="rights.add || rights.edit"
            variant="success"
            class="ml-1"
            :disabled="saving"
            @click="openHoliday()"
          >
            Mark Holiday
          </b-button>
          <b-button
            class="ml-1 btn-icon"
            variant="outline-primary"
            v-b-tooltip.hover.top
            title="Reports"
            @click="openReport()"
          >
            <feather-icon icon="BarChart2Icon" />
          </b-button>
          <b-button
            class="ml-1 btn-icon"
            variant="outline-primary"
            v-b-tooltip.hover.top
            title="Settings"
            @click="visibility3 = true"
            :disabled="settLoading"
          >
            <b-spinner v-if="settLoading" small />
            <feather-icon v-else icon="SettingsIcon" />
          </b-button>
          <b-button
            v-if="rights.delete"
            variant="outline-danger"
            class="ml-1 btn-icon"
            v-b-tooltip.hover.top
            title="Delete"
            @click="DeleteAtt()"
            :disabled="deleting"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
          <!-- <b-button
            class="ml-1 btn-icon"
            variant="outline-primary"
            v-b-tooltip.hover.top
            title="Messages"
            @click="openMessage()"
          >
            <feather-icon icon="MailIcon" />
          </b-button> -->
        </b-row>

        <b-row class="mt-1" v-if="showGrid">
          <b-col md="12">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              placeholder="Search by name"
            />
          </b-col>
          <b-col>
            <b-table
              class="mt-1"
              :items="filters"
              :fields="mode == 'biometric' ? bioFields : fields"
              show-empty
              :busy="gridLoading || saving"
              responsive
              hover
              small
              selectable
              select-mode="single"
              @row-selected="openDetail($event[0])"
            >
              <template #table-busy>
                <div
                  class="d-flex justify-content-center mb-3"
                  style="margin-top: 50px"
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    variant="primary"
                    label="Spinning"
                  ></b-spinner>
                </div>
              </template>
              <template #cell(index)="data">
                <b-badge variant="light-primary">
                  {{ gridData.indexOf(data.item) + 1 }}
                </b-badge>
              </template>
              <template #cell(id)="data">
                <b-badge variant="light-primary">
                  {{ data.item.id }}
                </b-badge>
              </template>
              <template #cell(name)="data">
                <b-badge variant="light-primary">
                  {{ data.item.name }}
                </b-badge>
              </template>
              <template #cell(father_name)="data">
                <b-badge variant="light-primary">
                  {{ data.item.father_name }}
                </b-badge>
              </template>

              <template #cell(department)="data">
                <b-badge style="margin-inline: 2px" variant="light-primary">
                  {{ data.item.department }}
                </b-badge>
              </template>
              <template #cell(timein)="data">
                <b-badge
                  variant="light-primary"
                  v-if="data.item.obj.timeIn"
                  @click.stop="
                    (rights.add || rights.edit) && (data.item.obj.timeIn = null)
                  "
                >
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(data.item.obj.timeIn))
                  }}
                </b-badge>
                <b-form-group
                  v-else-if="rights.add || rights.edit"
                  class="mb-0"
                >
                  <b-input-group>
                    <flat-pickr
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        altFormat: 'h:i K',
                        altInput: true,
                        dateFormat: 'Y-m-d\\TH:i:S',
                        defaultHour: 8,
                      }"
                      v-model="filters[data.index].inputTimein"
                      class="form-control"
                    />
                    <b-input-group-append
                      is-text
                      @click.stop="filters[data.index].inputTimein = null"
                      v-b-tooltip.hover.top
                      title="Clear"
                    >
                      <feather-icon
                        class="cursor-pointer"
                        size="16"
                        icon="XIcon"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </template>
              <template #cell(timeout)="data">
                <b-badge
                  variant="light-primary"
                  v-if="data.item.obj.timeOut"
                  @click.stop="
                    (rights.add || rights.edit) &&
                      (data.item.obj.timeOut = null)
                  "
                >
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(data.item.obj.timeOut))
                  }}
                </b-badge>
                <b-form-group
                  v-else-if="rights.add || rights.edit"
                  class="mb-0"
                >
                  <b-input-group>
                    <flat-pickr
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        altFormat: 'h:i K',
                        altInput: true,
                        dateFormat: 'Y-m-d\\TH:i:S',
                        defaultHour: 12,
                      }"
                      v-model="filters[data.index].inputTimeout"
                      class="form-control"
                    />
                    <b-input-group-append
                      is-text
                      @click.stop="filters[data.index].inputTimeout = null"
                      v-b-tooltip.hover.top
                      title="Clear"
                    >
                      <feather-icon
                        class="cursor-pointer"
                        size="16"
                        icon="XIcon"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </template>

              <!-- <template #cell(profile)="data">
                      <b-avatar
                        size="36"
                        class=""
                        variant="primary"
                        :src="data.item.picture"
                      />
                    </template> -->
              <template #cell(status)="data">
                <div class="d-flex align-items-center">
                  <b-button
                    pill
                    size="sm"
                    style="margin-inline: 2px"
                    :variant="
                      data.item.obj.status == 'P'
                        ? 'success'
                        : 'outline-success'
                    "
                    :disabled="!rights.add && !rights.edit"
                    @click="changeAtt(data.index, 'P')"
                  >
                    P
                  </b-button>
                  <b-button
                    pill
                    size="sm"
                    style="margin-inline: 2px"
                    :variant="
                      data.item.obj.status == 'A' ? 'danger' : 'outline-danger'
                    "
                    :disabled="!rights.add && !rights.edit"
                    @click="changeAtt(data.index, 'A')"
                  >
                    A
                  </b-button>
                  <b-button
                    v-if="doubleObj.valueBool == true"
                    pill
                    size="sm"
                    style="margin-inline: 2px"
                    :variant="
                      data.item.obj.status == 'AA' ? 'danger' : 'outline-danger'
                    "
                    :disabled="!rights.add && !rights.edit"
                    @click="changeAtt(data.index, 'AA')"
                  >
                    AA
                  </b-button>
                  <b-button
                    pill
                    size="sm"
                    style="margin-inline: 2px"
                    :variant="
                      data.item.obj.status == 'L' ? 'info' : 'outline-info'
                    "
                    :disabled="!rights.add && !rights.edit"
                    @click="changeAtt(data.index, 'L')"
                  >
                    L
                  </b-button>
                  <b-button
                    pill
                    size="sm"
                    style="margin-inline: 2px"
                    :variant="
                      data.item.obj.status == 'SL' ? 'info' : 'outline-info'
                    "
                    :disabled="!rights.add && !rights.edit"
                    @click="changeAtt(data.index, 'SL')"
                  >
                    SL
                  </b-button>
                  <b-button
                    pill
                    size="sm"
                    style="margin-inline: 2px"
                    :variant="
                      data.item.obj.status.toLowerCase() == 'late'
                        ? 'warning'
                        : 'outline-warning'
                    "
                    :disabled="!rights.add && !rights.edit"
                    @click="changeAtt(data.index, 'Late')"
                  >
                    Late
                  </b-button>
                  <b-button
                    pill
                    size="sm"
                    style="margin-inline: 2px"
                    :variant="
                      data.item.obj.status == 'H' ? 'info' : 'outline-info'
                    "
                    :disabled="!rights.add && !rights.edit"
                    @click="changeAtt(data.index, 'H')"
                  >
                    H
                  </b-button>
                  <!-- <b-button
                    variant="outline-primary"
                    class="btn-icon ml-1"
                    v-b-tooltip.hover.top
                    title="View Details"
                    @click="openDetail(data.item)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button> -->
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BAvatar,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BMediaBody,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";

export default {
  components: {
    //Multiselect,
    BAvatar,
    BMedia,
    BMediaBody,
    BMediaAside,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vSelect,
    flatPickr,
    BInputGroupAppend,
    FullCalendar,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadInfo();
      this.loadAttSettings();
      this.LoadSettings();
      // this.loadSummary();
      this.loadgrid();
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.gridData.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    let yList = [];
    for (let i = 2020; i <= 2040; i++) {
      yList.push(i);
    }
    return {
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        headerToolbar: {
          start: "title",
          end: "",
        },
        events: [
          // { title: "P", start: new Date() },
          // { title: "A", start: "2024-03-05T00:00:00" },
        ],
        eventResizableFromStart: true,
        dragScroll: true,
        dayMaxEvents: 2,
        // navLinks: true,
        eventClassNames({ event: e }) {
          const colorName =
            e.title == "P"
              ? "success"
              : e.title == "A" || e.title == "AA"
              ? "danger"
              : e.title == "L" || e.title == "H" || e.title == "SL"
              ? "info"
              : "warning";

          return [`text-${colorName}`];
        },
        eventClick: this.openEditAtt,
      },
      attMonth: new Date().getMonth() + 1,
      attYear: new Date().getFullYear(),
      currentObj: {
        status: "",
      },
      savingAtt: false,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      rights: {},
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      config2: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      statusOptions: [
        { name: "All", value: "" },
        { name: "Present", value: "P" },
        { name: "Absent", value: "A" },
        { name: "Leave", value: "L" },
        { name: "Late", value: "Late" },
      ],
      request: false,
      saving: false,
      colorDep: [],
      visibility2: false,

      sidebarTitle: "Add Balance",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "S.No", key: "index" },
        { label: "id", key: "id" },
        { label: "name", key: "name" },
        { label: "father name", key: "father_name" },
        // { label: "designation", key: "designation" },
        { label: "status", key: "status" },
        // { key: "actions", label: "actions" },
      ],
      bioFields: [
        { label: "S.No", key: "index" },
        { label: "id", key: "id" },
        { label: "name", key: "name" },
        { label: "father name", key: "father_name" },
        // { label: "designation", key: "designation" },
        { label: "time in", key: "timein" },
        { label: "time out", key: "timeout" },
        { label: "status", key: "status" },
      ],
      items: [],
      gridData: [],
      departments: [],
      mode: null,
      repObj: {
        date: "",
        depIDs: [0],
        status: [],
      },
      repDate: "",

      depText: "Department",
      selected: null,
      rangeDate: null,
      myObj: {
        date: new Date().toJSON().split("T")[0],
      },
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
      msgObj: {
        dt: "",
        depIDs: [0],
        status: "",
      },
      msgIDs: [0],
      showGrid: false,
      gridLoading: false,
      visibility3: false,
      notiTeacher: {},
      notiManager: {},
      doubleObj: {},
      settLoading: false,
      vueTelOptions: { placeholder: "Enter contact number" },
      dropOptions: {
        disabled: false,
        showDialCodeInList: true,
        showFlags: true,
      },
      phoneerror: false,
      savingSettings: false,
      attSettings: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        notifyArrival: false,
        notifyDeparture: false,
        showDues: false,
        showRecent: false,
        autoHide: false,
        hideAfterSeconds: 5,
        attendanceMode: "",
      },
      showDetails: false,
      otherLoading: false,
      detailItems: [],
      detailFields: [
        { label: "date", key: "date" },
        { label: "status", key: "status" },
        { label: "time in", key: "timein" },
        { label: "time out", key: "timeout" },
      ],
      detailBioFields: [
        { label: "S.No", key: "index" },
        { label: "date", key: "date" },
        { label: "time in", key: "timein" },
        { label: "time out", key: "timeout" },
        { label: "status", key: "status" },
      ],
      savingOther: false,
      countObj: {
        present: 0,
        absent: 0,
        leave: 0,
        late: 0,
      },
      summaryObj: {
        present: 0,
        absent: 0,
        leave: 0,
        late: 0,
        holidays: 0,
        suspended: 0,
        shortLeave: 0,
      },
      summLoading: false,
      months: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
      years: yList,
      deleting: false,
      holiObj: {
        dtFrom: "",
        dtTo: "",
        departmentIDs: [0],
      },
      visibility4: false,
      holiDate: "",
      marking: false,
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    openHoliday() {
      this.holiObj = {
        dtFrom: "",
        dtTo: "",
        departmentIDs: [0],
      };
      this.holiDate = "";
      this.visibility4 = true;

      var elem = this.$refs["hol_dep"];
      elem.state = undefined;
      var elem = this.$refs["hol_dateF"];
      elem.state = undefined;
      var elem = this.$refs["hol_dateT"];
      elem.state = undefined;
    },
    setHoliDept() {
      if (this.holiObj.departmentIDs.at(-1) == 0) {
        this.holiObj.departmentIDs = [0];
      } else {
        this.holiObj.departmentIDs = this.holiObj.departmentIDs.filter(
          (el) => el != 0
        );
      }
    },
    async markHoliday() {
      // console.log(this.holiObj);
      if (
        !this.holiObj.dtFrom ||
        !this.holiObj.dtTo ||
        this.holiObj.departmentIDs.length == 0
      ) {
        return this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else if (this.holiObj.dtFrom > this.holiObj.dtTo) {
        return this.$bvToast.toast("Please select the date correctly!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // console.log(this.holiObj);
        this.marking = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "StaffAttendance/MarkHolidays?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: {
            departmentIDs:
              this.holiObj.departmentIDs[0] == 0
                ? []
                : this.holiObj.departmentIDs,
            dtFrom: this.holiObj.dtFrom,
            dtTo: this.holiObj.dtTo,
          },
          context: this,
          token: this.$store.state.userData.token,
          message: "Attendance marked as holiday",
        });
        this.marking = false;
        if (status) {
          this.visibility4 = false;
        }
      }
    },

    async DeleteAtt() {
      if (this.myObj.date) {
        let result = await Swal.fire({
          title: "Are you sure?",
          html: `<div class='text-left'><p class='mb-50'>Do you want to delete the attendance for</p><b>${new Date(
            this.myObj.date
          ).toLocaleDateString("en-UK", {
            year: "numeric",
            day: "numeric",
            month: "short",
          })}</b></div>`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, Delete it!",
        });
        if (result.isConfirmed) {
          this.deleting = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "StaffAttendance/DeleteAttendace?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId +
              "&dt=" +
              this.myObj.date,
            message: "Attendance deleted successfully!",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.deleting = false;
          if (status) {
            this.gridData = [];
          }
        }
      }
    },

    async loadSummary() {
      this.summLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Staff/LoadAttendanceByDate?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dt=" +
          this.myObj.date +
          "&role=staff",
        token: this.$store.state.userData.token,
        dashboard: true,
      };
      let res = await this.get(obj);
      this.summaryObj = res.summary;
      // console.log(this.summaryObj);
      this.summLoading = false;
    },

    getVariant(title) {
      const vt =
        title == "P"
          ? "success"
          : title == "A" || title == "AA"
          ? "danger"
          : title == "L" || title == "H" || title == "SL"
          ? "info"
          : "warning";

      return vt;
    },
    openDetail(item) {
      // console.log(item);
      this.detailObj = item;
      this.showDetails = true;

      this.attMonth = new Date().getMonth() + 1;
      this.attYear = new Date().getFullYear();
      this.loadAtt();
      // this.loadDetails("");
    },
    openEditAtt({ event: clickedEvent }) {
      // console.log(clickedEvent);
      if (this.rights.add || this.rights.edit) {
        const { id, extendedProps, start } = clickedEvent;
        let dt = `${start.getFullYear()}-${(start.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${start.getDate().toString().padStart(2, "0")}`;

        this.currentObj = { ...extendedProps, id: parseInt(id), date: dt };
        // console.log(this.currentObj);
        this.$bvModal.show("modal-att");
      }
    },
    async saveAtt() {
      this.savingAtt = true;
      var status = await this.put({
        url:
          this.$store.state.domain +
          "staffAttendance/" +
          this.currentObj.id +
          "?db=" +
          this.$store.state.userData.db,
        message: "Attendance updated successfully.",
        context: this,
        body: this.currentObj,
        token: this.$store.state.userData.token,
      });
      this.savingAtt = false;
      if (status) {
        this.loadAtt();
        this.$bvModal.hide("modal-att");
      }
    },
    change(val) {
      this.attMonth = val;
      this.loadAtt();
    },
    async loadAtt() {
      this.dataLoading = true;
      let dt = `${this.attYear}-${this.attMonth
        .toString()
        .padStart(2, "0")}-01`;

      var obj = {
        url:
          this.$store.state.domain +
          "Staff/LoadAttendanceCalendar?db=" +
          this.$store.state.userData.db +
          "&tID=" +
          this.detailObj.id +
          "&month=" +
          this.attMonth +
          "&year=" +
          this.attYear,
        token: this.$store.state.userData.token,
        dashboard: true,
      };
      let res = await this.get(obj);
      // console.log("res:", res);
      if (res.data.length > 0) {
        this.calendarOptions.events = res.data.map((el) => ({
          ...el,
          title: el.status,
          start: el.date,
        }));
      } else {
        this.calendarOptions.events = [];
      }
      this.countObj = res.summary;

      this.dataLoading = false;
      setTimeout(() => {
        var elem = this.$refs.refCalendar.getApi();
        elem.gotoDate(dt);
      }, 200);
    },

    async loadDetails(val) {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Staff/LoadAttendance?db=" +
          this.$store.state.userData.db +
          "&tID=" +
          this.detailObj.id +
          "&duration=" +
          val,
        token: this.$store.state.userData.token,
        dashboard: true,
      };
      let res = await this.get(obj);
      this.detailItems = res.data;
      this.countObj = res.summary;
      // console.log("res:", res);
      this.dataLoading = false;
    },

    changeAtt(ind, val) {
      this.filters[ind].obj.status = val;
    },

    openReport() {
      const fd = new Date();
      const firstDate = `${fd.getFullYear()}-${(fd.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-01`;

      const ld = new Date(fd.getFullYear(), fd.getMonth() + 1, 0);
      const lastDate = `${ld.getFullYear()}-${(ld.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${ld.getDate().toString().padStart(2, "0")}`;

      // console.log(firstDate, lastDate);
      this.repObj = {
        dtFrom: firstDate,
        dtTo: lastDate,
        // depIDs: [0],
        depIDs: 0,
        status: [],
      };
      this.repDate = `${firstDate} to ${lastDate}`;
      this.visibility = true;

      var elem = this.$refs["rep_dep"];
      elem.state = undefined;
      var elem = this.$refs["rep_date"];
      elem.state = undefined;
    },
    setRepDate(sd, dateStr) {
      // console.log(sd, dateStr);
      if (dateStr) {
        if (sd.length > 1) {
          let date = dateStr.split(" to ");
          if (date.length == 1) {
            this.repObj.dtFrom = date[0];
            this.repObj.dtTo = date[0];
          } else {
            this.repObj.dtFrom = date[0];
            this.repObj.dtTo = date[1];
          }
        } else {
          this.repObj.dtFrom = dateStr;
          this.repObj.dtTo = null;
        }
      } else {
        this.repObj.dtFrom = null;
        this.repObj.dtTo = null;
      }
      // console.log(this.repObj);
    },
    setStatus() {
      if (this.repObj.status[this.repObj.status.length - 1] == "") {
        this.repObj.status = [""];
      } else {
        this.repObj.status = this.repObj.status.filter((el) => el != "");
      }
      // console.log(this.repObj.status);
    },
    setDept() {
      if (this.repObj.depIDs[this.repObj.depIDs.length - 1] == 0) {
        this.repObj.depIDs = [0];
      } else {
        this.repObj.depIDs = this.repObj.depIDs.filter((el) => el != 0);
      }
      // console.log(this.repObj.depIDs);
    },

    async getReport() {
      // console.log(this.repObj);
      if (
        !this.repObj.dtFrom ||
        !this.repObj.dtTo
        // ||
        // this.repObj.depIDs.length == 0
        // this.repObj.status.length == 0
      ) {
        return this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // let status = "";
        // if (this.repObj.status[0] !== "") {
        //   status = this.repObj.status;
        // }
        // console.log(this.repObj, status, ids);
        this.request = true;

        var url =
          `https://${this.reportDomain}.myskool.app/Attendance/ShowStaff?dtFrom=` +
          this.repObj.dtFrom +
          "&dtTo=" +
          this.repObj.dtTo +
          "&dbb=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&depID=" +
          this.repObj.depIDs;

        window.open(url, "_blank");
        this.request = false;
      }
    },

    openMessage() {
      this.msgObj = {
        dt: "",
        depIDs: [0],
        status: "P",
      };
      this.visibility2 = true;

      var elem = this.$refs["msg_dep"];
      elem.state = undefined;
      var elem = this.$refs["msg_date"];
      elem.state = undefined;
    },
    setMsgDept() {
      if (this.msgIDs[this.msgIDs.length - 1] == 0) {
        this.msgIDs = [0];
      } else {
        this.msgIDs = this.msgIDs.filter((el) => el != 0);
      }
      // console.log(this.msgIDs);
    },

    async saveMessage() {
      // console.log(this.msgObj);
      if (this.msgIDs.length == 0 || this.msgObj.dt == "") {
        return this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.msgIDs[0] !== 0) {
          this.msgObj.depIDs = this.msgIDs;
        } else {
          this.msgObj.depIDs = [];
        }
        // console.log(this.msgObj);

        this.request = true;
        // var status = await this.post({
        //   url:
        //     this.$store.state.domain +
        //     " ?db=" +
        //     this.$store.state.userData.db +
        //     "&cID=" +
        //     this.$store.state.userData.cId,
        //   body: this.msgObj,
        //   message: "Message send successfully!",
        //   context: this,
        //   token: this.$store.state.userData.token,
        // });
        this.request = false;
        // if (status) {
        //   this.visibility2 = false;
        // }
      }
    },
    CheckAccount() {
      var elem = this.$refs["account"];
      if (this.myObj.account == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckBalance() {
      var elem = this.$refs["balance"];
      var re = /^[0-9]+$/;
      if (re.test(this.myObj.balance) && this.myObj.balance !== 0) {
        this.myObj.balance = parseInt(this.myObj.balance);
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },
    async loadgrid() {
      // console.log(this.myObj);
      if (this.myObj.date !== "") {
        this.gridLoading = true;
        this.showGrid = true;

        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "StaffAttendance/LoadAttendanceGrid?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&dt=" +
            this.myObj.date,
          // body: null,
          attendance: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.loadSummary();
          this.gridData = status;
          this.gridData = this.gridData.map((el) => ({
            ...el,
            inputTimein: el.obj.timeIn,
            inputTimeout: el.obj.timeOut,
          }));
          // console.log(this.gridData[0]);
        }
        this.gridLoading = false;
      } else {
        this.$bvToast.toast("Please select the date.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    async LoadSettings() {
      this.settLoading = true;
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["notify-teacher", "notify-manager", "double-absent"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });

      // console.log(res);
      this.notiTeacher = res.find((el) => el.key == "notify-teacher");
      if (!this.notiTeacher) {
        this.notiTeacher = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          key: "notify-teacher",
          value: "notify-teacher",
          valueBool: false,
          valueNumber: null,
        };
      }
      this.notiManager = res.find((el) => el.key == "notify-manager");
      if (!this.notiManager) {
        this.notiManager = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          key: "notify-manager",
          value: "",
          valueBool: false,
          valueNumber: null,
        };
      }
      this.doubleObj = res.find((el) => el.key == "double-absent");
      if (!this.doubleObj) {
        this.doubleObj = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          key: "double-absent",
          value: "",
          valueBool: false,
          valueNumber: null,
        };
      }
      // console.log(this.notiTeacher,this.notiManager, this.doubleObj);
      this.settLoading = false;
    },
    CheckContact(s, e) {
      if (this.notiManager.value != "") {
        if (e.valid) {
          this.phoneerror = false;
          this.notiManager.value = e.number;
          // console.log(this.notiManager.value);
        } else {
          this.phoneerror = true;
        }
      } else {
        this.phoneerror = false;
      }
    },
    async saveSettings() {
      if (this.notiManager.valueBool && this.notiManager.value.trim() == "") {
        this.phoneerror = true;
      } else if (!this.notiManager.valueBool) this.phoneerror = false;
      if (this.phoneerror) {
        this.$bvToast.toast("Invalid contact entered", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else {
        // console.log(this.notiTeacher, this.notiManager);
        this.savingSettings = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "AttendanceSettings/Save?db=" +
            this.$store.state.userData.db,
          body: this.attSettings,
          importing: true,
          context: this,
          token: this.$store.state.userData.token,
        });

        status = await this.post({
          url:
            this.$store.state.domain +
            "Settings/SaveNew?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: [this.notiTeacher, this.notiManager, this.doubleObj],
          importing: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "CheckIcon",
              text: "Setting saved successfully!",
              variant: "success",
            },
          });
          this.visibility3 = false;
          this.loadAttSettings();
          this.LoadSettings();
        }
        this.savingSettings = false;
      }
    },
    async LoadInfo() {
      var obj = {
        url:
          this.$store.state.domain +
          "staffdepartments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.departments = await this.get(obj);
      if (this.departments.length > 0) {
        this.departments.unshift({ name: "All Departments", id: 0 });
      }
      // console.log("dept", this.departments);
    },
    async loadAttSettings() {
      var obj2 = {
        url:
          this.$store.state.domain +
          "AttendanceSettings/LoadBiometricSetting?campusID=" +
          this.$store.state.userData.cId +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      let sett = await this.get(obj2);
      // console.log(sett);
      if (sett) {
        this.attSettings = sett;
        this.mode = sett.attendanceMode;
        // console.log(this.attSettings);
      }
    },

    async updateLate() {
      if (this.myObj.date) {
        // this.loadSummary();
        this.gridLoading = true;

        const date = new Date(this.myObj.date);
        const fd = this.myObj.date.split("-");
        const firstDate = `${fd[0]}-${fd[1]}-01`;

        const ld = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const lastDate = `${ld.getFullYear()}-${(ld.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${ld.getDate().toString().padStart(2, "0")}`;

        // console.log(firstDate);
        // console.log(lastDate);

        var status = await this.get({
          url:
            this.$store.state.domain +
            "StaffAttendance/UpdateLateComing?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&dtFrom=" +
            firstDate +
            "&dtTo=" +
            lastDate,
          context: this,
          token: this.$store.state.userData.token,
        });
        this.gridLoading = false;
        // console.log(status);
        if (status == "Updated") {
          this.loadgrid();
        }
      }
    },
    async save() {
      if (this.gridData.length != 0) {
        this.saving = true;
        this.gridData.forEach((el) => {
          el.obj.timeIn = el.inputTimein == "" ? null : el.inputTimein;
          el.obj.timeOut = el.inputTimeout == "" ? null : el.inputTimeout;
        });
        var status = await this.post({
          url:
            this.$store.state.domain +
            "StaffAttendance/SaveWeb?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.gridData,
          message: "Attendance saved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.saving = false;
        if (status) {
          this.loadgrid();
          // this.loadSummary();
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        // var status = await this.delete({
        //   url:
        //     this.$store.state.domain +
        //     "OpeningBalance/" +
        //     id +
        //     "?db=" +
        //     this.$store.state.userData.db,
        //   body: null,
        //   message: "Opening Balance removed successfully.",
        //   context: this,
        // });
        // if (status) this.LoadData();
        console.log("balance deleted");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/apps/calendar.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.card .card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
  margin-bottom: 0.5rem;
}
.input-group {
  width: 140px;
}
</style>
